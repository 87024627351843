<template>
  <router-view/>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
}

html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #F0F3FB;
}
</style>
