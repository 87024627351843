import { useCookies } from '@vueuse/integrations/useCookies';
import { env } from '../../env.cligenerated.json';

export default () => {
  const cookies = useCookies([`${env}-refresh-token`]);

  let userId = null;

  try {
    const accessToken = cookies.get(`${env}-refresh-token`);

    // eslint-disable-next-line no-unused-vars
    const [_, tokenPayload, __] = accessToken.split(".");
    const { 
      userId: userIdFromToken = null 
    } = JSON.parse(window.atob(tokenPayload));  

    userId = userIdFromToken;

  } catch(e) {
    console.error(e)
  }

  return {
    userId
  }
};