<template>
  <div 
    v-if="isLoading"
    class="loading-container"
  >
    <i class="material-icons">refresh</i>
    loading...
  </div>

  <div 
    v-else
    class="app"
  >
    <div class="header-title">
      Steps (RWC)

      <div class="header-title__actions">
        <button 
          v-if="!isEditMode"
          title="Refresh" 
          :class="[{ 
            'loading': isRefreshing, 
            'disabled': isDisabled 
          }]"
          @click="refresh"
        >
          <i class="material-icons">refresh</i>
        </button>

        <!-- <button 
          title="Refresh" 
          :class="[{ 'disabled': isDisabled }]"
          @click="toggleEditMode"
        >
          <i class="material-icons">{{ isEditMode ? 'done' : 'edit' }}</i>
        </button> -->

         <button 
          :title="`Logout as ${userId}`" 
          :class="[{ 'disabled': isDisabled }]"
          @click="logout"
        >
          <i class="material-icons">logout</i>
        </button>
      </div>
    </div>

    <div class="search-container" v-if="!isEditMode && false">
      <i class="material-icons">search</i>
      <input 
        v-model="filterQuery" 
        placeholder="Filter" 
        :class="[{ 'disabled': isDisabled }]"
      />
    </div>

    <div class="footer">
      <input 
        v-model="newItemName" 
        :class="[{ 'disabled': isDisabled }]"
        placeholder="Add new step" 
      />
      <button 
        v-show="newItemName"
        title="Add new step"
        :class="[{ 'loading': isAdding, 'disabled': isDisabled }]"
        @click="addStep"
      >
        <i class="material-icons">{{ isAdding ? 'refresh' : 'add' }}</i>
      </button>
    </div>

    <div class="items-container">
      <div 
        class="item"
        :class="[{ 'locked': item.lockedByUserId }]"
        v-for="(item, index) in filteredItems"
        :key="index"
      >
        <div class="item__info">
          <span v-if="item.lockedByUserId" class="item__info-locked-by">locked by @{{ item.lockedByUserName }}</span>
          <div class="item__title">
            {{ item.label }}
          </div>
        </div>

        <button 
          v-if="!isEditMode && item.lockedByUserId" 
          class="item__lock-button"
          title="Unlock step"
          :class="[{ 
            'non-interactive': item.lockedByUserId !== userId,
            'loading': processingStepId === item.id,
            'unlock-visible': processingStepId === item.id
          }]"
          @click="() => unlockStepById(item.id)"
        >
          <i class="material-icons">{{ processingStepId === item.id ? 'refresh' : 'lock' }}</i>
        </button>
        
        <button 
          v-if="!isEditMode && !item.lockedByUserId" 
          class="item__lock-button"
          title="Lock step"
          :class="[{ 
            'loading': processingStepId === item.id,
            'disabled': isDisabled 
          }]"
          @click="() => lockStepById(item.id)"
        >
          <i class="material-icons">{{ processingStepId === item.id ? 'refresh' : 'lock_open' }}</i>
        </button>

         <button
          class="item__lock-button delete"
          title="Delete step"
          :class="[{ 
            'loading': deletingStepId === item.id,
            'disabled': isDisabled 
          }]"
          @click="() => deleteStepById(item.id)"
        >
          <i class="material-icons">{{ deletingStepId === item.id ? 'refresh' : 'delete' }}</i>
        </button>

        <div 
          class="item__bg-text"
          :style="{ color: stringToColor(item.label) }"
        >
        {{ getNameInitials(item.label).slice(0, 3) }}
      </div>
      </div>

      <div 
        v-if="!filteredItems.length"
        class="no-results"
      >
        Nothing found
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';


import services from '../../services.cligenerated.json';

import useFetchWithAuthorization from '@/composables/useFetchWithAuthorization';
import useUserInfo from '@/composables/useUserInfo';
import { useCookies } from '@vueuse/integrations/useCookies';
import { env, hostedZone } from '../../env.cligenerated.json';

const cookiePrefix = env !== 'prod' ? `${env}-` : '';

const isDev = process.env.NODE_ENV === 'development';
const cookieDomain = isDev 
  ? 'localhost' 
  : env === 'prod' 
    ? `.${hostedZone}` 
    : `.${env}.${hostedZone}`

export default {
  setup() {
    const cookies = useCookies([`${cookiePrefix}access-token`, `${cookiePrefix}refresh-token`]);
    const { fetchWithAuth } = useFetchWithAuthorization();
    const { userId } = useUserInfo();

    const filterQuery = ref('');
    const newItemName = ref('');

    const isEditMode = ref(false);

    const isLoading = ref(true);
    const isRefreshing = ref(false);
    const isAdding = ref(false);
    const processingStepId = ref(null);
    const deletingStepId = ref(null);

    const items = ref([]);

    const filteredItems = computed(() => items.value.filter(({ label }) => {
      const normalizedItemName = label.toLowerCase().trim();
      const normalizedFilterQuery = filterQuery.value.toLowerCase().trim();

      return normalizedItemName.includes(normalizedFilterQuery);
    }));
    const isDisabled = computed(() => deletingStepId.value || processingStepId.value || isAdding.value || isRefreshing.value || isLoading.value);

    const loadSteps = async () => {
      try {
        const { 
          items: steps = [] 
        } = await fetchWithAuth(`https://${services['steps-locker-api']}/items`, {
          method: "GET"
        });

        items.value = steps.map(step => ({ ...step, lockedByUserName: null }));

        const lockedByUserIds = steps.reduce((memo, { lockedByUserId }) => {
          if(!lockedByUserId || memo.includes(lockedByUserId)) {
            return memo;
          }

          return [
            ...memo,
            lockedByUserId
          ]
        }, []);

        if(lockedByUserIds.length) {
          const userProfilesByIds = await Promise.all(
            lockedByUserIds.map(id => fetchWithAuth(`https://${services['auth-api']}/profile/${id}`, { method: "GET" }))
          );

          const usersMap = lockedByUserIds.reduce((memo, id, index) => {
            return {
              ...memo,
              [id]: userProfilesByIds[index]
            }
          }, {});

           items.value = items.value.map(
            step => ({ 
              ...step, 
              lockedByUserName: usersMap?.[step.lockedByUserId]?.username ?? null 
            })
          )
        }
      } catch(e) {
        console.error(e);
      }
    };

    const getNameInitials = (name) => name.split(' ').map(w => w[0]).join('');
    const stringToColor = (str) => {
    let hash = 0;
      for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xFF;
          color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    }

    const refresh = async () => {
      isRefreshing.value = true;
      filterQuery.value = '';

      await loadSteps();

      isRefreshing.value = false;
    };

    const addStep = async () => {
      isAdding.value = true;

      try {
        const result = await fetchWithAuth(`https://${services['steps-locker-api']}/items`, {
          method: "POST",
          body: JSON.stringify({
            label: newItemName.value
          })
        });

        console.log('add result', result);

        await loadSteps();
      } catch(e) {
        console.error(e);
      } finally {
        isAdding.value = false;
        newItemName.value = '';
      }
    };

    const lockStepById = async (id) => {
      processingStepId.value = id;

      try {
         const result = await fetchWithAuth(`https://${services['steps-locker-api']}/item/${id}/lock`, {
          method: "POST"
        });

        console.log('lock result', result);

        await loadSteps();
      } catch(e) {
        console.error(e);
      } finally {
         processingStepId.value = null;
      }
    };

    const unlockStepById = async (id) => {
      processingStepId.value = id;

      try {
         const result = await fetchWithAuth(`https://${services['steps-locker-api']}/item/${id}/lock`, {
          method: "DELETE"
        });

        console.log('unlock result', result);

        await loadSteps();
      } catch(e) {
        console.error(e);
      } finally {
         processingStepId.value = null;
      }
    };

    const deleteStepById = async (id) => {
      deletingStepId.value = id;

      try {
        const result = await fetchWithAuth(`https://${services['steps-locker-api']}/item/${id}`, {
          method: "DELETE"
        });

        console.log('remove result', result);

        await loadSteps();
      } catch(e) {
        console.error(e);
      } finally {
        deletingStepId.value = null;
      }
    };

    const toggleEditMode = () => isEditMode.value = !isEditMode.value;

    const logout = () => {
      cookies.remove(`${cookiePrefix}access-token`, { domain: cookieDomain });
      cookies.remove(`${cookiePrefix}refresh-token`, { domain: cookieDomain });

      const redirectUrl = window.location;

      window.location = !isDev
        ? `https://${services['auth']}?redirect_url=${redirectUrl}` 
        : `http://localhost:8080?redirect_url=${redirectUrl}`
    };

    onMounted(async () => {
      try {
        await loadSteps();
      } finally {
         isLoading.value = false;
      }
    });

    return {
      filterQuery,
      newItemName,

      userId,

      isEditMode,

      isLoading,
      isRefreshing,
      isAdding,
      processingStepId,
      deletingStepId,
      isDisabled,

      filteredItems,

      getNameInitials,
      stringToColor,

      refresh,
      addStep,
      lockStepById,
      unlockStepById,
      deleteStepById,

      logout,

      toggleEditMode
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes rotateAnimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.app {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-top: 32px;

  .header-title {
    width: 100%;
    max-width: 1000px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__actions {
      display: flex;
      align-items: center;

      button {
        flex-shrink: 0;
        background-color: transparent;
        border: none;
        opacity: 0.8;
        cursor: pointer;
        margin-top: 6px;

        &.loading {
          opacity: 0.5;
          pointer-events: none;

          i {
            transform-origin: center;
            animation: rotateAnimation 2s infinite linear;
          }
        }
        
        i {
          font-size: 17px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .search-container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 8px;
    
    i {
      opacity: 0.3;
      flex-shrink: 0;
    }

    input {
      padding: 16px 0;
      flex-grow: 1;
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }

  .items-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: calc(1250px + 16px + 16px + 16px + 16px);
    column-gap: 16px;
    row-gap: 16px;

    .item {
      $p: &;

      background: #fff;
      padding: 16px;
      border-radius: 4px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      min-height: 150px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
      position: relative;

      max-width: 100%;
      width: 250px;
      min-width: 250px;

      row-gap: 16px;
      column-gap: 16px;

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      &.locked {
        box-shadow: none;
        background: rgba(27, 27, 29, 0.12);
      }

      &__info {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        &-locked-by {
          opacity: 0.5;
          font-size: 12px;
        }
      }


      &__title {
        display: flex;
        column-gap: 8px;
        font-weight: bold;
        z-index: 2;
        
        i {
          opacity: 0.5;
          font-size: 14px;
          line-height: 1.2;
        }
      }

      &__bg-text {
        position: absolute;
        top: -8px;
        right: 16px;
        font-size: 80px;
        opacity: 0.08;
        z-index: 1;
        pointer-events: none;
        user-select: none;
      }

      &__lock-button {
        z-index: 3;
        flex-shrink: 0;
        background-color: transparent;
        border: none;
        opacity: 0.5;
        cursor: pointer;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 36px;
        position: relative;
        margin-top: -8px;
        margin-left: -8px;
        transition: opacity 0.15s ease;

        i {
          font-size: 18px;
        }

        &.delete {
          position: absolute;
          top: 0px;
          right: -8px;
          background: #fff;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          opacity: 0;
          pointer-events: none;
          z-index: 100;

          i {
            font-size: 12px;
          }
        }

        &:hover {
          opacity: 1;
        }

        &.loading {
          opacity: 0.5;
          pointer-events: none;

          i {
            transform-origin: center;
            animation: rotateAnimation 2s infinite linear;
          }
        }

        &.non-interactive {
          cursor: default;
          pointer-events: none;
        }
      }

      &:hover {
        .item__lock-button.delete {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    .no-results {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 128px 0;
      opacity: 0.5;
    }
  }

  .footer {
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 8px;
    

    button {
      flex-shrink: 0;
      background-color: transparent;
      border: none;
      opacity: 0.5;
      cursor: pointer;

      i {
        font-size: 16px;
      }

      &:hover {
        opacity: 1;
      }

      &.loading {
        opacity: 0.5;
        pointer-events: none;

        i {
          transform-origin: center;
          animation: rotateAnimation 2s infinite linear;
        }
      }
    }

    input {
      padding: 16px 0;
      flex-grow: 1;
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  background: #f2f2f2;

  i {
    font-size: 16px;
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
</style>
